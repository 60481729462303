<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2
          v-b-tooltip.hover.v-primary
          class="mb-25 font-weight-bolder"
          :title="data[0].statistic"
        >
          {{ kFormatter(data[0].statistic) }}
        </h2>
        <span>{{ data[0].title }}</span>
      </div>
      <b-avatar
        :variant="`light-${data[0].color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="data[0].icon"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
