<template>
  <div>
    <b-button
      variant="outline-secondary"
      class="float-right"
      @click="handleClearFilters"
    >
      Clear
    </b-button>
    <b-button
      class="mr-1 float-right"
      variant="primary"
      :disabled="isButtonDisabled"
      @click="handleApplyClick"
    >
      {{ buttonText }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton, // Include BButton in the components section
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    filterData: Object,
    // eslint-disable-next-line vue/require-default-prop
    applyFilters: Function,
    // eslint-disable-next-line vue/require-default-prop
    clearFilters: Function,
  },
  data() {
    return {
      filterChanged: false, // Track whether a filter has been changed
      applied: false, // Track whether filters are applied
    }
  },
  computed: {
    isButtonDisabled() {
      return (!this.filterChanged || this.applied)
    },
    buttonText() {
      return this.applied ? 'Applied' : 'Apply'
    },
  },
  watch: {
    filterData: {
      handler(newData) {
        if (Object.keys(newData).length !== 0) {
          this.filterChanged = true
        } else {
          this.filterChanged = false
        }
        this.applied = false
      },
      deep: true,
    },
  },
  methods: {
    handleApplyClick() {
      if (this.filterChanged) {
        this.applied = true // Mark filters as applied
        this.applyFilters()
        this.filterChanged = false
      }
    },
    handleClearFilters() {
      this.applied = false // Mark filters as not applied
      this.filterChanged = false // Reset the filter changed flag
      this.clearFilters()
    },
  },
}
</script>
