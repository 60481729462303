<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Created At"
              label-for="v-ref-no"
            >
              <div class="position-relative">
                <flat-pickr
                  id="v-ref-no"
                  v-model="filters['orders.created_at']"
                  :config="{ mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                  class="form-control"
                  placeholder="Search"
                />
                <div
                  v-if="filters['orders.created_at']"
                  class="flatpickr-cancel"
                  @click="clearDate('orders.created_at')"
                >
                  <feather-icon icon="XIcon" />
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-row>
        <b-col md="4">
          <StatisticCardHorizontal
            :data="deliveryCharge"
          />
        </b-col>
        <b-col md="4">
          <StatisticCardHorizontal
            :data="totalCommission"
          />
        </b-col>
        <b-col md="4">
          <StatisticCardHorizontal
            :data="total_amount"
          />
        </b-col>
      </b-row>
    </div>
    <div>
      <b-card
        no-body
      >
        <div
          class="m-2"
        >
          <b-row>
            <b-col
              md="12"
              class="d-flex justify-content-end mb-1 mb-md-0"
            >
              <TableButtons
                :items="items"
                :json_fieldz="json_fields"
                :name="`DeliveryOrdersReport - Page ${page}.xls`"
                :bulkname="`DeliveryOrdersReport.xls`"
                :fetch="fetchDataForMonthlySalesNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>
        <b-overlay
          id="overlay-background"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            sticky-header="100vh"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(customer_phone)="data">
              <div>{{ data.item.customer_phone }}</div>
              <div>{{ data.item.customer_secondary_phone }}</div>
            </template>
            <template #cell(collected_cod)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(delivery_charge)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(cod)="data">
              <span>{{ __numberWithCommas(data.value, true) }}</span>
            </template>
            <template #cell(weight)="data">
              {{ (data.value).toLocaleString() }}
            </template>
          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              class="d-flex align-items-center justify-content-sm-start"
              md="3"
            >
              <label>Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1 width-125"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              class="d-flex align-items-center justify-content-sm-end"
              md="9"
            >
              <span class="text-muted pagination-text mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import StatisticCardHorizontal from '@/components/report/StatisticCardHorizontal.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ReportRepository = RepositoryFactory.get('report')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    'v-select': vSelect,
    flatPickr,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BOverlay,
    BCardActions,
    StatisticCardHorizontal,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      filters: {},
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100],
      totalRows: 1,
      page: 1,
      meta: {},
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'waybill_number', label: 'Waybill Number', thClass: 'text-left', tdClass: 'text-left',
        },
        {
          key: 'order_no', label: 'Order No', thClass: 'text-left', tdClass: 'text-left',
        },
        { key: 'customer_name', label: 'Customer Name', sortable: true },
        { key: 'customer_address', label: 'Customer Address', sortable: true },
        { key: 'customer_phone', label: 'Customer Phone', sortable: false },
        { key: 'customer_email', label: 'Customer Email', sortable: false },
        {
          key: 'collected_cod', label: 'Collected COD', sortable: false, thClass: 'text-right', tdClass: 'text-right',
        },
        {
          key: 'delivery_charge', label: 'Delivery Charge', sortable: false, thClass: 'text-right', tdClass: 'text-right',
        },
        {
          key: 'weight', label: 'Weight', sortable: false, thClass: 'text-right', tdClass: 'text-right',
        },
        {
          key: 'cod', label: 'COD', sortable: false, thClass: 'text-right', tdClass: 'text-right',
        },
      ],
      json_fields: {
        'Waybill Number': 'waybill_number',
        'Order No': {
          field: 'order_no',
          callback: value => `"${value}"`,
        },
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Collected COD': 'collected_cod',
        'Delivery Charge': 'delivery_charge',
        Weight: 'weight',
        COD: 'cod',
      },
      items: [],
      // Card Data
      deliveryCharge: [
        {
          icon: 'TruckIcon',
          statistic: 0,
          title: 'Total Delivery Charge',
          color: 'primary',
        },
      ],
      totalCommission: [
        {
          icon: 'ActivityIcon',
          statistic: 0,
          title: 'Total COD',
          color: 'warning',
        },
      ],
      total_amount: [
        {
          icon: 'EditIcon',
          statistic: 0,
          title: 'Total Collected COD',
          color: 'info',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForMonthlySales()
      },
    },
    deep: true,
    page() {
      this.fetchDataForMonthlySales()
    },
    perPage() {
      this.fetchDataForMonthlySales()
    },
  },
  mounted() {
    this.totalRows = this.items.length
    this.fetchDataForMonthlySales()
  },
  methods: {
    async fetchDataForMonthlySales() {
      this.dataLoading = true
      this.items = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await ReportRepository.getDeliveryOrdersFinance(this.page, this.filterQuery, this.perPage, this.sort))
        this.items = data.data.data
        this.meta = data.data
        if (data.total_delivery === null || data.total_delivery === undefined) {
          this.deliveryCharge[0].statistic = 0
        } else {
          this.deliveryCharge[0].statistic = data.total_delivery
        }
        if (data.total_cod === null || data.total_cod === undefined) {
          this.totalCommission[0].statistic = 0
        } else {
          this.totalCommission[0].statistic = data.total_cod
        }
        if (data.total_collected_cod === null || data.total_collected_cod === undefined) {
          this.total_amount[0].statistic = 0
        } else {
          this.total_amount[0].statistic = data.total_collected_cod
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchDataForMonthlySalesNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await ReportRepository.getDeliveryOrdersFinanceNoPagination(this.filterQuery, this.sort))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    resetModal() {
      this.clearOnClick()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    handleChangePage(page) {
      this.page = page
      this.fetchDataForMonthlySales()
    },
    applyFilters() {
      this.fetchDataForMonthlySales()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchDataForMonthlySales()
      })
    },
    onClickRefresh() {
      this.fetchDataForMonthlySales()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/vue/libs/vue-flatpicker.scss';
</style>
